import queryParams from '@/services/queryParams'
import apiInstance from './index'

export default {
  async getLiteraryOrArtisticWorks(paramsObj = {}, filtersObj = {}) {
    const query = queryParams.serializeParams(paramsObj, filtersObj)
    return await apiInstance.get(`literary-or-artistic-works${query}`)
  },

  async storeLiteraryOrArtisticWork(data) {
    return await apiInstance.post('literary-or-artistic-works', data)
  },

  async getLiteraryOrArtisticWorkInfo(id) {
    return await apiInstance.get(`literary-or-artistic-works/${id}`)
  },

  async updateLiteraryOrArtisticWork(data) {
    return await apiInstance.put(`literary-or-artistic-works/${data.id}`, data)
  },

  async deleteLiteraryOrArtisticWork(data) {
    return await apiInstance.delete(`literary-or-artistic-works/${data.id}`)
  },
}
