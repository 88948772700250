<template>
  <b-row v-if="!applyOverlay">
    <!-- Obra -->
    <b-col md="12">
      <b-row>
        <b-col md="6">
          <h5 class="mt-1">
            <strong>{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.literaryOrArtisticWorkData') }}</strong>
          </h5>
        </b-col>
        <b-col md="6">
          <!-- <span
            v-if="nWorks > 1 && checkPermission(['DELETE_LITERARY_OR_ARTISTIC_WORK_REGISTRATION', 'ALLOW_ALL'])"
            class="float-right mt-1 ml-1"
          >
            {{ $t( 'buttons.delete') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="danger"
              @click="$emit('delete-work', actions.workId)"
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </span> -->
          <span
            v-if="checkPermission(['EDIT_LITERARY_OR_ARTISTIC_WORK_REGISTRATION', 'ALLOW_ALL'])"
            class="float-right mt-1"
          >
            {{ $t( 'buttons.edit') }}
            <b-button
              class="btn-icon rounded-circle"
              variant="secondary"
              @click="$emit('edit-work', actions.workId)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
          </span>
        </b-col>
      </b-row>
      <hr style="margin-top: 8px;">
    </b-col>
    <b-col md="12">
      <b-list-group>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.typesOfLiteraryOrArtisticWork') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.type_of_literary_or_artistic_work.labelables[0].label }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfRequest') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.date_of_request == null ? $t('generic.undefinedDate') : workInfo.date_of_request | formatDate() }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.dateOfConcession') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.date_of_concession == null ? $t('generic.undefinedDate') : workInfo.date_of_concession | formatDate() }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.titleOfLiteraryOrArtisticWork') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.title_of_literary_or_artistic_work }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.holderName') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.holder_name }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
        <b-list-group-item>
          <b-row>
            <b-col md="6">
              <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.creatorAndOrDesigner') }}</strong>
            </b-col>
            <b-col md="6">
              <span class="float-left">{{ workInfo.creator_and_or_designer }}</span>
            </b-col>
          </b-row>
        </b-list-group-item>
      </b-list-group>
      <b-list-group-item>
        <b-row>
          <b-col md="6">
            <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.applicationFile') }}</strong>
          </b-col>
          <b-col md="6">
            <span>. . .</span>
            <b-media
              class="float-right"
              no-body
            >
              <span
                class="i-c-pointer cursor-pointer float-right"
                @click="showFile = !showFile"
              >
                {{ $t('generic.see') }}
                <feather-icon
                  v-if="!showFile"
                  icon="FileIcon"
                  size="17"
                  style="padding-bottom: 2px"
                />
              </span>
              <div
                v-if="showFile == true"
                class="ml-2"
                style="display: flex;"
              >
                <div
                  v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFile == true"
                  class="float-right"
                  style="display: flex;"
                >
                  <div v-if="isObject(workInfo.application_media_file) && workInfo.application_media_file.mime_type != 'application/pdf'">
                    <image-viewer
                      class="cursor-pointer mr-1"
                      :document-id="workInfo.application_file_hash"
                      :url-thumb="workInfo.application_media_file.url_thumb"
                      :height="'30'"
                      :width="'30'"
                      :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                      :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                    />
                  </div>
                  <div v-else>
                    <ViewerDocumentModal
                      class="cursor-pointer mr-1"
                      :title="workInfo.application_media_file ? workInfo.application_media_file.title : ''"
                      :document-id="workInfo.application_file_hash"
                      :height="'30'"
                      :width="'30'"
                      :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                      :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                    />
                  </div>
                  <div v-if="workInfo.application_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                    <span
                      class="float-right cursor-pointer"
                      @click="handleDownloadFile(workInfo.application_file_hash, workInfo.application_media_file.title)"
                    >
                      <i
                        class="icon-0-icons-dark-download btn-cursor"
                        style="font-size: 20px;"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </b-media>
          </b-col>
        </b-row>
      </b-list-group-item>
      <b-list-group-item>
        <b-row>
          <b-col md="6">
            <strong class="float-left">{{ $t('clubs.intellectualProperty.formLiteraryOrArtisticWorks.labels.contractFile') }}</strong>
          </b-col>
          <b-col md="6">
            <span>. . .</span>
            <b-media
              class="float-right"
              no-body
            >
              <span
                class="i-c-pointer cursor-pointer float-right"
                @click="showFileTwo = !showFileTwo"
              >
                {{ $t('generic.see') }}
                <feather-icon
                  v-if="!showFileTwo"
                  icon="FileIcon"
                  size="17"
                  style="padding-bottom: 2px"
                />
              </span>
              <div
                v-if="showFileTwo == true"
                class="ml-2"
                style="display: flex;"
              >
                <div
                  v-if="checkPermission(['VIEW_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL']) && showFileTwo == true"
                  class="float-right"
                  style="display: flex;"
                >
                  <div v-if="isObject(workInfo.contract_media_file) && workInfo.contract_media_file.mime_type != 'application/pdf'">
                    <image-viewer
                      class="cursor-pointer mr-1"
                      :document-id="workInfo.contract_file_hash"
                      :url-thumb="workInfo.contract_media_file.url_thumb"
                      :height="'30'"
                      :width="'30'"
                      :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                      :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                    />
                  </div>
                  <div v-else>
                    <ViewerDocumentModal
                      class="cursor-pointer mr-1"
                      :title="workInfo.contract_media_file ? workInfo.contract_media_file.title : ''"
                      :document-id="workInfo.contract_file_hash"
                      :height="'30'"
                      :width="'30'"
                      :permission-show="'VIEW_CONTRACT_FILE_MEMBERS'"
                      :permission-download="'DOWNLOAD_CONTRACT_FILE_MEMBERS'"
                    />
                  </div>
                  <div v-if="workInfo.contract_file_hash != null && checkPermission(['DOWNLOAD_CONTRACT_FILE_MEMBERS', 'ALLOW_ALL'])">
                    <span
                      class="float-right cursor-pointer"
                      @click="handleDownloadFile(workInfo.contract_file_hash, workInfo.contract_media_file.title)"
                    >
                      <i
                        class="icon-0-icons-dark-download btn-cursor"
                        style="font-size: 20px;"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </b-media>
          </b-col>
        </b-row>
      </b-list-group-item>
    </b-col>
    <login-modal
      :permission="validPermission"
      @change="toAccess"
    />
  </b-row>
  <b-row v-else>
    <b-col
      md="12"
      class="i-height-div"
    />
  </b-row>
</template>

<script>
/* eslint-disable object-curly-newline */
import { mapActions } from 'vuex'
import { isObject, downloadFile } from '@core/utils/utils'
import { BListGroup, BListGroupItem, BButton } from 'bootstrap-vue'
import literaryOAWService from '@/services/literaryOrArtisticWorkService'
import ImageViewer from '@/views/member/ImageViewer.vue'
import ViewerDocumentModal from '@/views/member/ViewerDocumentModal.vue'
import LoginModal from '@/views/member/LoginModal.vue'

export default {
  components: {
    BListGroup,
    BListGroupItem,
    BButton,
    ImageViewer,
    ViewerDocumentModal,
    LoginModal,
  },

  props: {
    applyOverlay: {
      type: Boolean,
      default: false,
    },
    actions: {
      type: Object,
      default: () => {
      },
    },
    nWorks: {
      type: Number,
      default: null,
    },
    checkPermission: {
      type: Function,
      default: null,
    },
  },

  data() {
    return {
      showFile: false,
      showFileTwo: false,
      validPermission: null,
      workInfo: {},
    }
  },

  watch: {
    'actions.workId': function (value) {
      if(value == null) return
      this.workInfo = {}
      this.fetchWorkInfo()
    },
  },

  methods: {
    downloadFile,
    isObject,
    ...mapActions({ mediaFileShow: 'mediaFile/show' }),

    fetchWorkInfo() {
      literaryOAWService.getLiteraryOrArtisticWorkInfo(this.actions.workId).then(({ data }) => {
        this.workInfo = data.data
        this.$emit('stop-overlay')
      }).catch(error => {
        this.$emit('stop-overlay')
        this.responseCatch(error)
      })
    },

    async toAccess(permissionData) {

    },

    async handleDownloadFile(fileHash, title) {
      this.isLoading = true
      await this.mediaFileShow(fileHash).then(response => {
        const { data: dataDocument } = response
        this.downloadFile(dataDocument, title)
        this.isLoading = false
      }).catch(error => {
        this.isLoading = false
        this.responseCatch(error)
      }).finally(() => {
        this.isLoading = false
      })
    },
  },
}
</script>

<style scoped>
.i-c-pointer:hover {
  color: #6abeff;
}
</style>
